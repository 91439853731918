import React from "react";
import {Organization, OrganizationRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useNavigate} from "react-router-dom";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";

export const OrganizationEditPage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(OrganizationRestControllerApiFactory);
    const companyId = apiManager.companyId;


    const form = useForm({
        fetcher: (id) => ctrl.getOrganization(id),
        updater: (bean) => ctrl.saveOrganization(bean),
        remover: (id) => ctrl.deleteOrDismissOrganization(id),
        factory: () => {
            return {company: {id: companyId}} as Organization;
        },
        extraActions: [
            {
                label: "Contactos",
                action: () => {
                    navigate("/app/admin/marketing/contact?orgId=" + form.getIdOrErr());
                },
            },
            {
                label: "Peticiones",
                action: () => {
                    navigate("/app/admin/marketing/request?organizationId=" + form.getIdOrErr());
                },
            }
        ],
        idParamName: "organizationId"
    } as UseFormProps<Organization>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Organización"}>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            type={"text"}
                            field={"name"}
                            label={"Nombre"}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            type={"text"}
                            field={"email"}
                            label={"Email general de la empresa"}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormToggle
                            field={"gsbit"}
                            label={"Gsbit"}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormToggle
                            field={"mallorca"}
                            label={"Mallorca"}
                        />
                    </Grid>
                </Grid>

                <FormInput type={"text"} field={"mainActivity"} label={"Actividad principal"}/>

                <FormInput type={"text"} field={"linkedin"} label={"Linkedin"}/>
                <FormInput type={"text"} field={"twitter"} label={"twitter"}/>
                <FormInput type={"text"} field={"facebook"} label={"facebook"}/>
                <FormInput type={"text"} field={"github"} label={"github"}/>
                <FormInput type={"text"} field={"website"} label={"website"}/>

                <FormInput type={"text"} field={"observation"}
                           label={"Comentarios privados"} rows={10}/>

            </FormContent>
        </>
    );
};