import React, {useEffect, useState} from "react";

import {
    Bill,
    BillLine,
    BillRestControllerApiFactory,
    Customer,
    CustomerRestControllerApiFactory
} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import {TypeConf, types} from "../../../../components/form/types";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {Grid, TextField} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {fileLocalSave} from "../../../../components/form/form_inputs/form_file";
import {useApiManager} from "../../../../components/utils/api";
import FormDataGrid, {FormDataGridColumn,} from "../../../../components/form/form_inputs/form_data_grid";
import {useNavigate} from "react-router-dom";
import {numberToString} from "../../../../components/utils/numbers";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";

export const BillEditPage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();
    const companyId = apiManager.companyId;
    const ctrl = apiManager.factory(BillRestControllerApiFactory);
    const customerCtrl = apiManager.factory(CustomerRestControllerApiFactory);

    const [customerNotes, setCustomerNotes] = useState<string>("");

    const showCustomerNotes = (type: TypeConf<Customer>, customer: Customer | null) => {
        if (customer) {
            setCustomerNotes(customer.observation || "");
        } else {
            setCustomerNotes("");
        }
    };

    const form = useForm({
        fetcher: (id) => ctrl.getBill(id),
        updater: (bean) => ctrl.saveBill(bean),
        remover: (id) => ctrl.deleteOrDismissBill(id),
        factory: () => {
            return {
                company: {id: companyId},
                template: {id: 50, name: "Factura Estándar"}
            } as Bill;
        },
        extraActions: [
            {
                label: "Duplicar",
                action: () => {
                    const id = form.getId();
                    if (confirm("Guarde lo cambios antes de duplicar.\n" +
                        "La pantalla se posicionará sobre el registro nuevo.\n\n" +
                        "¿Seguro que desea duplicar la factura?") && id) {
                        apiManager.executeRaw(ctrl.duplicateBill(id)).then((resp) => {
                            navigate("/app/admin/billing/bill/" + resp.data.id);
                            window.location.reload();
                        });
                    }
                },
            },
            {
                label: "Pdf",
                action: () => {
                    const id = form.getId();
                    if (id) {
                        apiManager.executeRaw(ctrl.viewPdfBill(id)).then((resp) => {
                            fileLocalSave(resp);
                        });
                    }
                },
            },
        ],
    } as UseFormProps<Bill>);

    useEffect(() => {
        const customerId = form.propModel.value?.customer?.id;
        if (customerId) {
            apiManager.executeRaw(customerCtrl.getCustomer(customerId)).then((resp) => {
                showCustomerNotes(types.customers, resp.data);
            });
        }
    }, [form.getId()]);

    const columns: FormDataGridColumn<BillLine>[] = [
        {editable: true, key: "code", name: "Código", width: "15%"},
        {editable: true, key: "concept", name: "Description", width: "30%"},
        {editable: true, key: "quantity", name: "Cantidad", width: "20%"},
        {editable: true, key: "unitNetPrice", name: "Precio Neto", width: "15%"},
        {editable: true, key: "taxType", name: "%IVA", width: "15%"},
    ];

    return (
        <>
            <FormContent form={form} title={"Formulario de factura"}>

                <Grid container spacing={3}>
                    <Grid item sm={9}>
                        <Grid container spacing={3}>
                            <Grid item sm={3}>
                                <FormInput type={"number"} field={"number"} label={"Número"}/>
                            </Grid>
                            <Grid item sm={3}>
                                <FormInput field={"code"} label={"Código"}/>
                            </Grid>
                            <Grid item sm={3}>
                                <FormSelect field={"template"} label={"Plantilla"} type={types.templates}/>
                            </Grid>
							<Grid item sm={3}>
								<FormToggle field={"doubtful"} label={"Incobrable"} />
							</Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item sm>
                                <FormAutocomplete
                                    field={"customer"}
                                    label={"Cliente"}
                                    type={types.customers}
                                    onChange={showCustomerNotes}
                                />
                            </Grid>
                            <Grid item sm>
                                <FormAutocomplete field={"person"} label={"Persona"} type={types.people}/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item sm>
                                <FormInput field={"issueDate"} type={"date"} label={"Emisión"}/>
                            </Grid>
                            <Grid item sm>
                                <FormInput field={"sentDate"} type={"date"} label={"Enviada"}/>
                            </Grid>
                            <Grid item sm>
                                <FormInput field={"advanceDate"} type={"date"} label={"Anticipada"}/>
                            </Grid>
                            <Grid item sm>
                                <FormInput field={"paymentDate"} type={"date"} label={"Pago"}/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} py={2}>
                            <Grid item sm={12} md={6}>
                                <FormInput field={"description"} type={"text"} label={"Descripción"} rows={3}/>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <FormInput field={"comments"} type={"text"} label={"Comentarios"} rows={3}/>
                            </Grid>
                        </Grid>

                        {form.getId() && (
                            <FormDataGrid
                                title={"Líneas de factura"}
                                columns={columns}
                                field={"lines"}
                                onRemove={(ids) => {
                                    return apiManager
                                        .execute(ctrl.removeLinesBill(form.getIdOrErr(), ids))
                                        .then(() => {
                                            apiManager.enqueueSnackbar("Eliminación realizada");
                                        });
                                }}
                                onAppend={() => apiManager.execute(ctrl.appendLineBill(form.getIdOrErr()))}
                            />
                        )}
                    </Grid>
                    <Grid item sm={3}>

                        {form.propModel.value?.totals && (
                            <>
                                <TextField
                                    sx={{mt:0, mb:1}}
                                    fullWidth
                                    inputProps={{style: {textAlign: "right"}}}
                                    label={"Cantidad"}
                                    value={numberToString(form.propModel.value?.totals.quantity)}
                                    disabled/>
                                <TextField
                                    sx={{my:1}}
                                    fullWidth
                                    inputProps={{style: {textAlign: "right"}}}
                                    label={"SubTotal"}
                                    value={numberToString(form.propModel.value?.totals.subTotal)}
                                    disabled/>
                                <TextField
                                    sx={{my:1}}
                                    fullWidth
                                    inputProps={{style: {textAlign: "right"}}}
                                    label={"IVA"}
                                    value={numberToString(form.propModel.value?.totals.taxes)}
                                    disabled/>
                                <TextField
                                    sx={{my:1}}
                                    fullWidth
                                    inputProps={{style: {textAlign: "right"}}}
                                    label={"Total"}
                                    value={numberToString(form.propModel.value?.totals.total)}
                                    disabled/>
                            </>
                        )}

                        <TextField
                            sx={{mt:2}}
                            fullWidth
                            label={"Notas del cliente"}
                            value={customerNotes}
                            disabled
                            minRows={5}
                            multiline={true}
                            inputProps={{style: {fontSize: "0.8em"}}}/>
                    </Grid>
                </Grid>


            </FormContent>
        </>
    );
};