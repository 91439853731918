import {OrgRequest, OrgRequestRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {parseParamId} from "../../../../components/form/form_utils";


export const useRequest = (requestId: number| undefined): [(OrgRequest | undefined), React.Dispatch<React.SetStateAction<OrgRequest | undefined>>] => {
    const apiManager = useApiManager();
    const [request, setRequest] = useState<OrgRequest|undefined>();
    const ctrl = apiManager.factory(OrgRequestRestControllerApiFactory);

    useEffect(()=>{
        if (requestId){
            apiManager
                .execute(ctrl.getOrgRequest(requestId))
                .then(c => setRequest(c));

        }
    },[requestId]);
    return [request, setRequest];
};

export const OrgRequestListPage = () => {
    const apiManager = useApiManager();
    const [searchParams, setSearchParams] = useSearchParams();
    const organizationId = parseParamId(searchParams,"organizationId");
    const contactId = parseParamId(searchParams,"contactId");

    const listConfig: AppListConfig<OrgRequest> = {
        title: "Listado de peticiones",
        onClick: "/app/admin/marketing/request",
        decorator: (bean: OrgRequest) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_DISMISSED : "" }}>
                <Grid item sm>
                    {bean.requested}
                </Grid>
                <Grid item sm>
                    {bean.organization.name}
                </Grid>
                <Grid item sm>
                    {bean.title}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(OrgRequestRestControllerApiFactory);
            return ctrl.pageOrgRequest(companyId, currentPage, size, undefined, organizationId, contactId, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};