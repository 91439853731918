import {Organization, OrganizationRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React, {useEffect, useState} from "react";
import {abbreviate} from "../../../../components/utils/functions";


export const useOrganization = (organizationId: number | undefined): [(Organization | undefined), React.Dispatch<React.SetStateAction<Organization | undefined>>] => {
	const apiManager = useApiManager();
	const [organization, setOrganization] = useState<Organization | undefined>();
	const organizationCtrl = apiManager.factory(OrganizationRestControllerApiFactory);

	useEffect(() => {
		if (organizationId) {
			apiManager
			.execute(organizationCtrl.getOrganization(organizationId))
			.then(c => setOrganization(c));

		}
	}, [organizationId]);
	return [organization, setOrganization];
};

export const OrganizationListPage = () => {
	const apiManager = useApiManager();

	const listConfig: AppListConfig<Organization> = {
		title: "Listado de Organizaciones",
		onClick: "/app/admin/marketing/organization",
		decorator: (bean: Organization) => (
			<Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
				<Grid item sm={3}>
					{bean.name}
				</Grid>
				<Grid item sm>
					{abbreviate(bean.observation, 80)}
				</Grid>

			</Grid>
		),
		supplier: async (currentPage, size, term, showDismissed) => {
			const companyId = apiManager.companyId;
			const ctrl = apiManager.factory(OrganizationRestControllerApiFactory);
			return ctrl.pageOrganization(companyId, currentPage, size, undefined, term, showDismissed);
		},
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};