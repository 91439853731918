import {Route} from "react-router-dom";
import {WikiPageListPage} from "./pages/admin/employee/wiki";
import {SECTION_MARKETING, WikiPageEditPage} from "./pages/admin/employee/wiki/form";
import * as React from "react";
import {MarketingLayout} from "./pages/admin/marketing/layout";
import {AdminMarketingPage} from "./pages/admin/marketing";
import {ContactListPage} from "./pages/admin/marketing/contact";
import {ContactEditPage} from "./pages/admin/marketing/contact/form";
import {ContactEventListPage} from "./pages/admin/marketing/contact/event";
import {ContactEventEditPage} from "./pages/admin/marketing/contact/event/form";
import {OrganizationListPage} from "./pages/admin/marketing/organization";
import {OrganizationEditPage} from "./pages/admin/marketing/organization/form";
import {ContactTypeListPage} from "./pages/admin/marketing/contacttype";
import {ContactTypeEditPage} from "./pages/admin/marketing/contacttype/form";
import {ContactEventTypeListPage} from "./pages/admin/marketing/eventtype";
import {ContactEventTypeEditPage} from "./pages/admin/marketing/eventtype/form";
import {OrgRequestListPage} from "./pages/admin/marketing/request";
import {OrgRequestEditPage} from "./pages/admin/marketing/request/form";
import SideBarItem from "./sidebar_item";
import {NoteAlt, PeopleOutline} from "@mui/icons-material";
import FortIcon from "@mui/icons-material/Fort";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ThreePIcon from "@mui/icons-material/ThreeP";


const AdminMarketingRoutes = () => {
	return (
		<Route path="/app/admin/marketing" element={<MarketingLayout/>}>
			<Route index element={<AdminMarketingPage/>}/>
			<Route path="/app/admin/marketing/contact" element={<ContactListPage/>}/>
			<Route path="/app/admin/marketing/contact/new"
				   element={<ContactEditPage/>}/>
			<Route path="/app/admin/marketing/contact/:contactId"
				   element={<ContactEditPage/>}/>
			<Route path="/app/admin/marketing/contact/:contactId/event"
				   element={<ContactEventListPage/>}/>
			<Route path="/app/admin/marketing/contact/:contactId/event/new"
				   element={<ContactEventEditPage/>}/>
			<Route path="/app/admin/marketing/contact/:contactId/event/:id"
				   element={<ContactEventEditPage/>}/>
			<Route path="/app/admin/marketing/organization"
				   element={<OrganizationListPage/>}/>
			<Route path="/app/admin/marketing/organization/:organizationId"
				   element={<OrganizationEditPage/>}/>
			<Route path="/app/admin/marketing/organization/new"
				   element={<OrganizationEditPage/>}/>
			<Route path="/app/admin/marketing/contacttype"
				   element={<ContactTypeListPage/>}/>
			<Route path="/app/admin/marketing/contacttype/:id"
				   element={<ContactTypeEditPage/>}/>
			<Route path="/app/admin/marketing/contacttype/new"
				   element={<ContactTypeEditPage/>}/>
			<Route path="/app/admin/marketing/eventtype"
				   element={<ContactEventTypeListPage/>}/>
			<Route path="/app/admin/marketing/eventtype/:id"
				   element={<ContactEventTypeEditPage/>}/>
			<Route path="/app/admin/marketing/eventtype/new"
				   element={<ContactEventTypeEditPage/>}/>
			<Route path="/app/admin/marketing/request" element={<OrgRequestListPage/>}/>
			<Route path="/app/admin/marketing/request/:requestId"
				   element={<OrgRequestEditPage/>}/>
			<Route path="/app/admin/marketing/request/new"
				   element={<OrgRequestEditPage/>}/>
			<Route path="/app/admin/marketing/wiki"
				   element={<WikiPageListPage section={SECTION_MARKETING}/>}/>
			<Route path="/app/admin/marketing/wiki/:id"
				   element={<WikiPageEditPage section={SECTION_MARKETING}/>}/>
			<Route path="/app/admin/marketing/wiki/new"
				   element={<WikiPageEditPage section={SECTION_MARKETING}/>}/>
		</Route>
	)
}

export default AdminMarketingRoutes;

export const AdminMarketingMenu =  () => {
	return (
		<>
			<SideBarItem
				href={"/app/admin/marketing/contact"}
				label={"Contactos"}
				icon={<PeopleOutline/>}
			/>
			<SideBarItem
				href={"/app/admin/marketing/organization"}
				label={"Organizaciones"}
				icon={<FortIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/marketing/request"}
				label={"Peticiones"}
				icon={<RequestPageIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/marketing/eventtype"}
				label={"Tipos de evento"}
				icon={<InsertInvitationIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/marketing/contacttype"}
				label={"Tipos de contacto"}
				icon={<ThreePIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/marketing/wiki"}
				label={"Wiki"}
				icon={<NoteAlt/>}
			/>
		</>
	)
}