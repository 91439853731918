import {Box, Chip, Grid} from "@mui/material";
import FormAutocomplete from "../form_autocomplete";
import {TypeConf} from "../../types";
import React, {useContext, useState} from "react";
import {FormContext} from "../form_buttons";


export interface FormAutoListProp<T> {
    field: string;
    label: string;
    type: TypeConf<T>;
    onChange?: (type: TypeConf<T>, value: T[]) => void;
    creator?: (userInput: string) => Promise<T>;
}

function FormAutoList<T>({field, label, type, onChange, creator}: FormAutoListProp<T>) {

    const formContext = useContext(FormContext);
    const model = formContext.form;
    const modelField = model.field(field);
    model.register(field, () => {
        return selectedItems;
    }, t => setSelectedItems(t));

    const [selectedItems, setSelectedItems] = useState<T[]>((modelField.value() || []) as T[]);

    const deleteItem = (item: T): void => {
        const cleanItems = selectedItems.filter(i => type.valuer(i) != type.valuer(item));
        setSelectedItems(cleanItems);
        notifyChange(cleanItems);
    }

    const appendItem = (item: T): void => {
        const newItems = selectedItems.concat(item);
        setSelectedItems(newItems);
        notifyChange(newItems);
    }

    const notifyChange = (items: T[]): void => {
        if (onChange) {
            onChange(type, items);
        }
    }

    return (
        <>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormAutocomplete field={field+"_inner"} label={label} type={type}
                                          helpText={creator? "Añade elementos con Enter":undefined}
                                          onKeyUp={(event, userInput) => {
                                              if (event.code == "Enter" && creator) {
                                                  if (confirm("Desea crear el registro [" + userInput + "]")) {
                                                      creator(userInput).then(elem => {
                                                          appendItem(elem);
                                                      });
                                                  }
                                              }
                                          }}
                                          onChange={(type, elem) => {
                                              if (elem) {
                                                  const found = selectedItems.find(i => type.valuer(i) == type.valuer(elem));
                                                  if (!found) {
                                                      appendItem(elem);
                                                  }
                                              }
                                          }}/>
                    </Grid>
                    <Grid item xs>
                        {selectedItems.map((elem, idx) => (
                            <Chip sx={{margin: 1}} key={idx} label={type.labeler(elem)} variant="outlined"
                                  onDelete={() => deleteItem(elem)}/>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default FormAutoList;