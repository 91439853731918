import {CalendarDay, Vacation, VacationRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {Grid} from "@mui/material";
import {dateFromToString} from "../../../../components/utils/dates";
import {COLOR_AZUL_ELECTRICO} from "../../../../components/layout/colors";
import {useApiManager} from "../../../../components/utils/api";
import {useSearchParams} from "react-router-dom";
import {parseParamId} from "../../../../components/form/form_utils";
import {useState} from "react";
import moment from "moment";


export const VacationsListPage = () => {
    const apiManager = useApiManager();
    let [searchParams, setSearchParams] = useSearchParams();
    let [calendar, setCalendar] = useState<CalendarDay[]>([]);

    const queryPersonId = parseParamId(searchParams, "personId");

	function countWorkingDays(startDate: string, endDate: string) {
		const format = 'YYYY-MM-DD';
		let currentDate = moment(startDate, format);
    	const end = moment(endDate, format);  // Asegúrate de incluir el día final en el ciclo
		let workingDays = 0;

		while (currentDate <= end) {
			let isWeekend = currentDate.day() === 0 || currentDate.day() === 6;
			let isHoliday = calendar
			    .find(calDay => calDay.date == currentDate.format(format))
				?.holiday;

			if (!isWeekend && !isHoliday) {
				workingDays++;
			}

			// Incrementa el día
			currentDate.add(1, 'days');
		}

		return workingDays;
	}

    const listConfig: AppListConfig<Vacation> = {
        title: "Ausencias ",
        onClick: "/app/admin/employee/vacations",
        decorator: (bean: Vacation) => (
            <Grid
                container
                style={{color: bean.state != "ACCEPTED" ? COLOR_AZUL_ELECTRICO : ""}}>
                <Grid item sm={2}>
                    {dateFromToString(bean.periodFrom)} &nbsp;
                    ({countWorkingDays(bean.periodFrom, bean.periodTo)})
                </Grid>
                <Grid item sm={2}>
                    {bean.person.surnames}, {bean.person.name}
                </Grid>
                <Grid item sm={2}>
                    {bean.type}
                </Grid>
                <Grid item sm={2}>
                    {bean.state}
                </Grid>
                <Grid item sm={2}>
                    {bean.title}
                </Grid>
                <Grid item sm={1}>
                    {bean.validator ? bean.validator.name : ""}
                </Grid>
                <Grid item sm={1}>
                    {bean.imputationYear}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(VacationRestControllerApiFactory);
            return ctrl.pageVacation(companyId, currentPage, size, undefined, queryPersonId,
                undefined, undefined, undefined, term, showDismissed)
                .then(resp => {
                    let days : string[] = []
                    resp.data.content?.forEach(vacation => {
                        days.push(vacation.periodFrom)
                        days.push(vacation.periodTo)
                    });
                    days = days.sort();
                    const start = days[0];
                    const end = days[days.length-1];
                    ctrl.getHolidayCalendarVacation(companyId, start, end).then(calResp => {
						setCalendar(calResp.data);
					})
                    console.log("Dates");
                    return resp;
                });
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};