import * as React from 'react';
import {createRoot} from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter, Route, Routes,} from "react-router-dom";

import App, {AppWelcomePage} from "./app";
import Login from "./pages/login";
import NotFoundPage from "./pages/404";
import {ThemeProvider} from "@mui/material";
import {theme} from "./components/layout/theme";
import {store} from "./store";
import {Provider} from 'react-redux';
import {SnackbarProvider} from "notistack";
import Recover from "./pages/recover";
import Signup from "./pages/signup";
import Redefine from "./pages/redefine";
import Validate from './pages/validate';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import "./styles/globals.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {AdminPage} from "./pages/admin";
import {AdminLayout} from "./pages/admin/layout";
import {UserProfile} from "./pages/user/profile";
import {CandidatePage} from './pages/candidate';
import moment from "moment/moment";
import {CandidateTestPage} from "./pages/candidate/test";
import {PublicRecruitmentsPage} from "./pages/candidate/recruitments";
import AdminBillingRoutes from "./route_admin_billings";
import AdminEmployeeRoutes from "./route_admin_employee";
import AdminRecruitmentRoutes from "./route_admin_recruitment";
import SuperRoutes from "./route_super";
import EmployeeRoutes from "./route_employee";
import AdminMarketingRoutes from "./route_admin_marketing";
import AdminRoutes from "./route_admin";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

moment.locale('es', {
	week: {
		dow: 1,
		doy: 1,
	},
	longDateFormat: {
		// https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/03-string-format/
		LT: "h:mm A",
		LTS: "h:mm:ss A",
		L: "DD/MM/YYYY",
		LL: "MMMM Do YYYY",
		LLL: "MMMM Do YYYY LT",
		LLLL: "dddd, MMMM Do YYYY LT"
	}

});

root.render(
	<React.StrictMode>
		<CssBaseline/>
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"es"}>
				<Provider store={store}>
					<SnackbarProvider>
						<BrowserRouter>
							<Routes>
								<Route index element={<Login/>}/>
								<Route path="/login" element={<Login/>}/>
								<Route path="/recover" element={<Recover/>}/>
								<Route path="/redefine" element={<Redefine/>}/>
								<Route path="/signup" element={<Signup/>}/>
								<Route path="/validate" element={<Validate/>}/>
								<Route path="/candidate" element={<CandidatePage/>}/>
								<Route path="/recruitments" element={<PublicRecruitmentsPage/>}/>
								<Route path="/candidate/:candidateId/test/:testId" element={<CandidateTestPage/>}/>
								<Route path="/app" element={<App/>}>
									<Route index element={<AppWelcomePage/>}/>
									<Route path="/app/admin" element={<AdminLayout/>}>
										<Route index element={<AdminPage/>}/>
										{AdminBillingRoutes()}
										{AdminEmployeeRoutes()}
										{AdminRecruitmentRoutes()}
										{AdminMarketingRoutes()}
									</Route>
									{AdminRoutes()}
									{SuperRoutes()}
									{EmployeeRoutes()}
									<Route path="/app/user/profile" element={<UserProfile/>}/>
								</Route>
								<Route path="*" element={<NotFoundPage/>}/>
							</Routes>
						</BrowserRouter>
					</SnackbarProvider>
				</Provider>
			</LocalizationProvider>
		</ThemeProvider>
	</React.StrictMode>
);