import {BillingLayout} from "./pages/admin/billing/layout";
import {Route} from "react-router-dom";
import {WikiPageListPage} from "./pages/admin/employee/wiki";
import {SECTION_SYSTEM, WikiPageEditPage} from "./pages/admin/employee/wiki/form";
import * as React from "react";
import {AdminAdminPage} from "./pages/admin/company";
import {CompanyEditPage} from "./pages/admin/company/form";
import {BackupListPage} from "./pages/admin/company/backup";
import {BackupEditPage} from "./pages/admin/company/backup/form";
import {TemplateListPage} from "./pages/admin/company/template";
import {TemplateEditPage} from "./pages/admin/company/template/form";
import SideBarItem from "./sidebar_item";
import {Backup, Book, LineStyle, NoteAlt} from "@mui/icons-material";
import {AdminLayout} from "./pages/admin/layout";


const AdminRoutes = () => {
	return (
		<Route path="/app/admin/company" element={<AdminLayout/>}>
			<Route index element={<AdminAdminPage/>}/>
			<Route path="/app/admin/company/:id" element={<CompanyEditPage/>}/>
			<Route path="/app/admin/company/backup" element={<BackupListPage/>}/>
			<Route path="/app/admin/company/backup/:id" element={<BackupEditPage/>}/>
			<Route path="/app/admin/company/backup/new" element={<BackupEditPage/>}/>
			<Route path="/app/admin/company/template" element={<TemplateListPage/>}/>
			<Route path="/app/admin/company/template/:id"
				   element={<TemplateEditPage/>}/>
			<Route path="/app/admin/company/template/new"
				   element={<TemplateEditPage/>}/>
			<Route path="/app/admin/company/wiki"
				   element={<WikiPageListPage section={SECTION_SYSTEM}/>}/>
			<Route path="/app/admin/company/wiki/:id"
				   element={<WikiPageEditPage section={SECTION_SYSTEM}/>}/>
			<Route path="/app/admin/company/wiki/new"
				   element={<WikiPageEditPage section={SECTION_SYSTEM}/>}/>
		</Route>
	);
}

export default AdminRoutes;

export const AdminMenu = () => {
	return (
		<>
			<SideBarItem
				href={"/app/admin/company/form"}
				label={"Ficha de empresa"}
				icon={<LineStyle/>}
			/>
			{/* no es listado */}
			<SideBarItem
				href={"/app/admin/company/backup"}
				label={"Copias de seguridad"}
				icon={<Backup/>}
			/>
			<SideBarItem
				href={"/app/admin/company/template"}
				label={"Plantillas"}
				icon={<Book/>}
			/>
			<SideBarItem
				href={"/app/admin/company/wiki"}
				label={"Wiki"}
				icon={<NoteAlt/>}
			/>
		</>
	)
}