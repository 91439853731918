import React from "react";
import {
    OrgRequest,
    OrgRequestRestControllerApiFactory,
    Skill,
    SkillRestControllerApiFactory
} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";
import FormAutoList from "../../../../components/form/form_inputs/form_autolist";

export const OrgRequestEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(OrgRequestRestControllerApiFactory);
    const skillCtrl = apiManager.factory(SkillRestControllerApiFactory)
    const companyId = apiManager.companyId;


    const form = useForm({
        fetcher: (id) => ctrl.getOrgRequest(id),
        updater: (bean) => ctrl.saveOrgRequest(bean),
        remover: (id) => ctrl.deleteOrDismissOrgRequest(id),
        factory: () => {
            return {
                requested: new Date().toISOString(),
                company: {
                    id: companyId
                },
                requestedTags: []
            } as any as OrgRequest;
        },
        idParamName: "requestId"
    } as UseFormProps<OrgRequest>);

    return (
        <>
            <FormContent form={form} title={"Formulario de petición"}>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            type={"date"}
                            field={"requested"}
                            label={"Fecha petición"}
                        />
                    </Grid>
                    <Grid item sm={9}>
                        <FormInput
                            type={"text"}
                            field={"title"}
                            label={"Título"}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormAutocomplete field={"organization"} label={"Organización"} type={types.organizations}/>
                    </Grid>
                    <Grid item sm>
                        <FormAutocomplete field={"contact"} label={"Contacto"} type={types.contacts}/>
                    </Grid>
                </Grid>

                <FormAutoList field={"requestedSkills"} label={"Peticiones"}  type={types.skills}
                              creator={(input)=> {
                                  return apiManager
                                      .execute(skillCtrl.saveSkill({
                                          label: input,
                                          position: 999,
                                          company: {id: companyId}
                                      } as Skill))
                              }} />

                <FormInput type={"text"} field={"observation"}
                           label={"Observación"} rows={10}/>

            </FormContent>
        </>
    );
};