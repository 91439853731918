import {ContactEvent, ContactEventRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../../components/utils/api";
import React from "react";
import {useParams} from "react-router-dom";
import {dateToString} from "../../../../../components/utils/dates";
import {useContact} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";

export const ContactEventListPage = () => {
    const apiManager = useApiManager();

    const params = useParams();
    const contactId = parsePathId(params, "contactId");
    const [contact, setContact] = useContact(contactId);

    const listConfig: AppListConfig<ContactEvent> = {
        title: "Listado de Eventos de "+contact?.surnamesAndName,
        onClick: "/app/admin/marketing/contact/" + contactId + "/event",
        decorator: (bean: ContactEvent) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_DISMISSED : "" }}>
                <Grid item sm>
                    {dateToString(bean.issueDate)}
                </Grid>
                <Grid item sm>
                    {bean.type?.name}
                </Grid>
                <Grid item sm>
                    {bean.description.substring(0,30)}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(ContactEventRestControllerApiFactory);
            return ctrl.pageContactEvent(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };



    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};