import React from "react";
import {ContactEvent, ContactEventRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../../components/form/form_model";
import {useApiManager} from "../../../../../components/utils/api";
import {useParams} from "react-router-dom";
import {types} from "../../../../../components/form/types";
import FormAutocomplete from "../../../../../components/form/form_inputs/form_autocomplete";
import {useContact} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";


export const ContactEventEditPage = () => {

    const apiManager = useApiManager();
    const ctrl = apiManager.factory(ContactEventRestControllerApiFactory);

    const params = useParams();
    const contactId = parsePathId(params, "contactId");

    const [contact, setContact] = useContact(contactId);

    const form = useForm({
        fetcher: (id) => ctrl.getContactEvent(id),
        updater: (bean) => ctrl.saveContactEvent(bean),
        remover: (id) => ctrl.deleteOrDismissContactEvent(id),
        factory: () => {
            return {contact: {id: contactId}} as ContactEvent;
        },
    } as UseFormProps<ContactEvent>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Evento de "+contact?.surnamesAndName}>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            type={"date"}
                            field={"issueDate"}
                            label={"Fecha"}
                        />
                    </Grid>
                    <Grid item sm={9}>
                        <FormAutocomplete
                            type={types.contactEventTypes}
                            field={"type"}
                            label={"Tipo"}
                        />
                    </Grid>
                </Grid>

                <FormInput type={"text"} field={"description"}
                           label={"Descripción"} rows={10}/>

            </FormContent>
        </>
    );
};