import React from "react";
import {Contact, ContactRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useNavigate} from "react-router-dom";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";

export const ContactEditPage = () => {
	const navigate = useNavigate();
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(ContactRestControllerApiFactory);
	const companyId = apiManager.companyId;


	const form = useForm({
		fetcher: (id) => ctrl.getContact(id),
		updater: (bean) => ctrl.saveContact(bean),
		remover: (id) => ctrl.deleteOrDismissContact(id),
		factory: () => {
			return {
				company: {id: companyId},
				locale: 'es'
			} as any as Contact;
		},
		extraActions: [
			{
				label: "Peticiones",
				action: () => {
					navigate("/app/admin/marketing/request?contactId=" + form.getIdOrErr());
				},
			},
			{
				label: "Eventos",
				action: () => {
					navigate("/app/admin/marketing/contact/" + form.getIdOrErr() + "/event");
				},
			}
		],
		idParamName: "contactId"
	} as UseFormProps<Contact>);

	return (
		<>
			<FormContent form={form} title={"Formulario de Contacto"}>

				<Grid container spacing={3}>
					<Grid item sm={3}>
						<FormInput
							type={"text"}
							field={"title"}
							label={"Título"}
						/>
					</Grid>
					<Grid item sm={3}>
						<FormInput
							type={"text"}
							field={"name"}
							label={"Nombre"}
						/>
					</Grid>
					<Grid item sm={3}>
						<FormInput
							type={"text"}
							field={"surnames"}
							label={"Apellidos"}
						/>
					</Grid>
					<Grid item sm={3}>
						<FormToggle field={"noDisturb"} label={"No molestar"}/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item sm>
						<FormAutocomplete field={"organization"} label={'Organización'} type={types.organizations}/>
					</Grid>
					<Grid item sm>
						<FormAutocomplete field={"type"} label={'Tipo'} type={types.contactTypes}/>
					</Grid>
				</Grid>

				<FormInput
					type={"text"}
					field={"position"}
					label={"Posición"}
				/>

				<Grid container spacing={3}>
					<Grid item sm>
						<FormInput
							type={"text"}
							field={"phone"}
							label={"Teléfono"}
						/>
					</Grid>
					<Grid item sm>
						<FormInput
							type={"text"}
							field={"locale"}
							label={"Idioma"}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item sm>
						<FormInput
							type={"text"}
							field={"email"}
							label={"Email"}
						/>
					</Grid>
				</Grid>


				<FormInput type={"text"} field={"linkedin"} label={"Linkedin"}/>
				<FormInput type={"text"} field={"twitter"} label={"twitter"}/>
				<FormInput type={"text"} field={"facebook"} label={"facebook"}/>
				<FormInput type={"text"} field={"github"} label={"github"}/>
				<FormInput type={"text"} field={"website"} label={"website"}/>

				<FormInput type={"text"} field={"observation"}
						   label={"Comentarios privados"} rows={10}/>

			</FormContent>
		</>
	);
};