import React from "react";
import {ContactType, ContactTypeRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";


export const ContactTypeEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(ContactTypeRestControllerApiFactory);
    const companyId = apiManager.companyId;


    const form = useForm({
        fetcher: (id) => ctrl.getContactType(id),
        updater: (bean) => ctrl.saveContactType(bean),
        remover: (id) => ctrl.deleteOrDismissContactType(id),
        factory: () => {
            return {company: {id: companyId}} as ContactType;
        },

    } as UseFormProps<ContactType>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Tipo de Contacto"}>

                <FormInput type={"text"} field={"name"} label={"Nombre"}/>
                <FormInput type={"text"} field={"description"}
                           label={"Descripción"} rows={10}/>

            </FormContent>
        </>
    );
};